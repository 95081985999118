
import AliOss from 'ali-oss'
import { getSignedData, getTimestamp, randomNum } from '../utils'
import config from '../config'
import { OssConfigType, OssMultipartUploadResult } from '../types/aliOss'
import { IAxiosSuccess } from '../interface'
import server from '../axiosInstance'
import { splitFileNameV2 } from '@/utils'

const { aliOssURL, secretKey } = config
interface Checkpoint {
  /** The file object selected by the user, if the browser is restarted, it needs the user to manually trigger the settings */
  file: any
  /** object key */
  name: string
  fileSize: number
  partSize: number
  uploadId: string
  doneParts: Array<{ number: number, etag: string }>
}

export interface multipartUploadOption {
  ossConfig: OssConfigType
  destination: string // 文件夹地址
  fileName: string // 指定文件名
}

/**
 * 获取阿里OSS配置
 * @param fileType
 * @returns
 */
export const getAliOssConfig = (fileType: number): Promise<IAxiosSuccess<{ credentials: OssConfigType }>> => {
  const data = {
    fileType,
    timestamp: getTimestamp(),
    random: randomNum(4),
    appId: 1000
  }
  const sign = getSignedData(data, secretKey)

  return server.request<null, IAxiosSuccess<{ credentials: OssConfigType }>>({
    url: `${aliOssURL}/v1/common/credentialsBySign`,
    method: 'GET',
    params: Object.assign(data, {
      sign
    })
  })
}

// 分片断点上传
let retryFileCount = 0
const retryCountMax = 3

/**
 * 阿里oss分片上传
 * @param config
 * @param file
 * @param progress
 * @param checkpoint
 * @returns
 */
export function multipartUpload(config: OssConfigType, file: File, progress: (arg0: any, arg1: any, arg2: AliOss) => void, option: Partial<multipartUploadOption>, checkpoint?: Checkpoint | undefined) {
  const client = new AliOss(config)
  return new Promise((resolve: (value: OssMultipartUploadResult) => void, reject) => {
    const fileName = splitFileNameV2(file.name)
    let url: string
    if (option.destination) {
      url = config.dir + option.destination + file.name
    } else {
      url = config.dir + fileName.prefix + '-' + Date.now() + '.' + fileName.suffix
    }
    client.multipartUpload(url, file, {
      progress: (p, checkpoint) => {
        progress(p, checkpoint, client)
      },
      checkpoint,
      timeout: 120000 // 设置超时时间
    }).then(res => {
      const result = Object.assign({
        path: option.destination ? config.cdnUrl + config.dir + option.destination : config.cdnUrl + config.dir,
        url: config.cdnUrl + encodeURI(res.name)
      }, res)
      resolve(result)
    }).catch(err => {
      if (retryFileCount <= retryCountMax) {
        retryFileCount++
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        multipartUpload(config, file, () => { }, { destination: option.destination })
        return
      }
      reject(err)
    })
  })
}
