import { SelectTypeItem } from '@/types/common'
import i18n from '@/locales/index'
const { t } = i18n.global

// 定义所有可能的语言列表
const languageOptions: Record<string, SelectTypeItem[]> = {
  sea: [
    {
      label: t('language.zh'),
      value: 'zh'
    },
    {
      label: t('language.zh-Hant'),
      value: 'zh-Hant'
    },
    {
      label: t('language.en'),
      value: 'en'
    },
    {
      label: t('language.ar'),
      value: 'ar'
    },
    {
      label: t('language.in'),
      value: 'in'
    },
    {
      label: t('language.ms'),
      value: 'ms'
    },
    {
      label: t('language.tl'),
      value: 'tl'
    },
    {
      label: t('language.vi'),
      value: 'vi'
    },
    {
      label: t('language.th'),
      value: 'th'
    }
  ],
  me: [
    {
      label: t('language.zh'),
      value: 'zh'
    },
    {
      label: t('language.en'),
      value: 'en'
    },
    {
      label: t('language.ar'),
      value: 'ar'
    },
    {
      label: t('language.in'),
      value: 'in'
    },
    {
      label: t('language.ms'),
      value: 'ms'
    },
    {
      label: t('language.vi'),
      value: 'vi'
    },
    {
      label: t('language.th'),
      value: 'th'
    }
  ]
}

const getLanguageList = (): SelectTypeItem[] => {
  const versionName = process.env.VUE_APP_NAME || 'sea'
  return languageOptions[versionName] || languageOptions.sea
}

export const languageList = getLanguageList()
