import { IHeaderTabItem } from './views/components/headerV2.vue'
import i18n from '@/locales/index'
const { t } = i18n.global
const use = process.env.VUE_APP_USE || ''
const versionName = process.env.VUE_APP_NAME || ''

const defaultHeader: IHeaderTabItem[] = [
  {
    title: t('route.home'),
    to: '/',
    name: 'Home'
  },
  {
    title: t('route.music'),
    to: '/music',
    name: 'Music'
  },
  {
    title: t('route.recharge'),
    to: '/recharge',
    name: 'Recharge'
  },
  {
    title: t('route.about'),
    to: '/about',
    name: 'About'
  },
  {
    title: t('route.privacy'),
    to: '/priv',
    name: 'Privacy'
  },
  {
    title: t('route.userve'),
    to: '/userve',
    name: 'Userve'
  }
  // {
  //   title: t('route.udesk'),
  //   to: '/udesk',
  //   name: 'Udesk'
  // }
]

const productHeader: IHeaderTabItem[] = [
  {
    title: t('route.home'),
    to: '/',
    name: 'Home'
  },
  {
    title: t('route.music'),
    to: '/music',
    name: 'Music'
  },
  // {
  //   title: t('route.udesk'),
  //   to: '/udesk',
  //   name: 'Udesk'
  // },
  // 印尼环境暂时屏蔽--充值
  ...(versionName === 'me' ? [
    {
      title: t('route.recharge'),
      to: '/recharge',
      name: 'Recharge'
    }
  ] : [])
]

const companyHeader: IHeaderTabItem[] = [
  {
    title: t('route.home'),
    to: '/',
    name: 'Home'
  },
  {
    title: t('route.about'),
    to: '/about',
    name: 'About'
  },
  {
    title: t('route.privacy'),
    to: '/priv',
    name: 'Privacy'
  },
  {
    title: t('route.userve'),
    to: '/userve',
    name: 'Userve'
  }
]

let header: IHeaderTabItem[] = defaultHeader

switch (use) {
  case '':
    header = defaultHeader
    break
  case 'product':
    header = productHeader
    break
  case 'company':
    header = companyHeader
    break
}

export default header
