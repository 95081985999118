export default {
  route: {
    home: 'Trang chủ',
    music: 'Tải nhạc lên',
    about: 'Về chúng tôi',
    upload: 'Tải lên',
    login: 'Đăng nhập',
    privacy: 'Thỏa thuận bảo mật',
    userve: 'Thỏa thuận người dùng',
    recharge: 'Nạp tiền',
    udesk: 'Dịch vụ khách hàng',
    quickGuide: 'Chỉ nam nhanh chóng nhập môn weelife',
    roomRewards: 'Vào phòng nhận thưởng',
    updateContent: 'CẬP NHẬT NỘI DUNG',
    friendSpace: 'Thưởng không gian bạn bè',
    userGroup: 'Nhóm người dùng chính thức',
    quickStartGuide: 'Chỉ nam nhanh chóng nhập môn weelife',
    ktvParty: 'Tiệc Karaoke'
  },
  webMeta: {
    title: 'weelife',
    titleCompany: 'Tân ngôn',
    description: 'Weelife là sản phẩm xã hội bằng giọng nói thực tế ảo được tạo ra đặc biệt dành cho giới trẻ thuộc Thế hệ genZ và đi đầu trong các xu hướng xã hội trực tuyến. Với sự hỗ trợ của công nghệ sản xuất trò chơi thế hệ tiếp theo, Weelife kết hợp hình ảnh ảo, cảnh ảo và giọng nói xã hội chất lượng cao, sự kết hợp mang lại trải nghiệm xã hội "siêu hiện diện" chưa từng có cho người dùng xã hội trên thiết bị đầu cuối di động và VR. Trong thế giới Weelife, bạn có thể thể hiện cá tính của mình thông qua hình ảnh và trang phục tinh tế; lối chơi tương tác xã hội phong phú và thú vị, giúp việc giao lưu giữa những người bạn mới trở nên thoải mái và tự nhiên; Cùng bạn bè thông qua những trải nghiệm được chia sẻ; giọng nói chân thực và chuyển động cơ thể sống động khiến quá trình tương tác trở nên giàu cảm xúc và ấm áp hơn; bạn thậm chí có thể xây dựng khung cảnh hoặc ngôi nhà mơ ước của riêng mình để vui chơi cùng bạn bè. Mục tiêu của Weelife là cho phép mọi người dùng thu được tiếng vang của chính họ và chạm đến sự gắn kết tâm hồn của họ trong thế giới không tưởng sôi động và ấm áp này. Weelife là một sản phẩm còn non trẻ và cần tăng trưởng liên tục để phục vụ tốt hơn cho đại đa số người dùng. Cho dù bạn là người chơi hay người sáng tạo, bất kể bạn ở đâu trên thế giới, chúng tôi mong muốn lắng nghe ý kiến ​​và đề xuất của bạn cũng như hợp tác với chúng tôi để xây dựng một điều không tưởng lý tưởng.',
    descriptionCompany: 'Weelife là sản phẩm xã hội bằng giọng nói thực tế ảo được tạo ra đặc biệt dành cho giới trẻ thuộc Thế hệ genZ và đi đầu trong các xu hướng xã hội trực tuyến. Với sự hỗ trợ của công nghệ sản xuất trò chơi thế hệ tiếp theo, Weelife kết hợp hình ảnh ảo, cảnh ảo và giọng nói xã hội chất lượng cao, ssự kết hợp mang lại trải nghiệm xã hội "siêu hiện diện" chưa từng có cho người dùng xã hội trên thiết bị đầu cuối di động và VR. Trong thế giới Weelife, bạn có thể thể hiện cá tính của mình thông qua hình ảnh và trang phục tinh tế; lối chơi tương tác xã hội phong phú và thú vị, giúp việc giao lưu giữa những người bạn mới trở nên thoải mái và tự nhiên; Cùng bạn bè thông qua những trải nghiệm được chia sẻ; giọng nói chân thực và chuyển động cơ thể sống động khiến quá trình tương tác trở nên giàu cảm xúc và ấm áp hơn; bạn thậm chí có thể xây dựng khung cảnh hoặc ngôi nhà mơ ước của riêng mình để vui chơi cùng bạn bè. Mục tiêu của Weelife là cho phép mọi người dùng thu được tiếng vang của chính họ và chạm đến sự gắn kết tâm hồn của họ trong thế giới không tưởng sôi động và ấm áp này. Weelife là một sản phẩm còn non trẻ và cần tăng trưởng liên tục để phục vụ tốt hơn cho đại đa số người dùng. Cho dù bạn là người chơi hay người sáng tạo, bất kể bạn ở đâu trên thế giới, chúng tôi mong muốn lắng nghe ý kiến ​​và đề xuất của bạn cũng như hợp tác với chúng tôi để xây dựng một điều không tưởng lý tưởng.',
    keywords: 'Weelife;ứng dụng Weelife;Weelifeapp;weelife;ứng dụng weelife;weelifeapp;WeelifeApp',
    keywordsCompany: 'Tân ngôn; Trang web chính thức tân ngôn Quảng Châu; Tân ngôn Quảng Châu; Công nghệ thông tin Tân ngôn Quảng Châu; newlang;newlang官网;newlang app;newlang App;Newlang'
  },
  errorPage404: {
    errMsg404: 'Lỗi yêu cầu mạng, không tìm thấy tài nguyên!',
    btnText: 'Mở ứng dụng',
    MDialog: {
      headerText: 'Quét mã QR để tải ứng dụng weelife',
      btnText: 'Tải xuống ứng dụng'
    }
  },
  public: {
    downloading: 'Đang tải xuống...',
    wait: 'Vui lòng chờ...',
    openFail: 'Không mở được APP, hãy thử đánh thức lại'
  },
  footer: {
    priv: 'Chính sách bảo mật',
    userve: 'Thỏa thuận người dùng',
    contact: 'Liên hệ với chúng tôi',
    email: 'Email hợp tác'
  },
  homePage: {
    content: {
      second: {
        content_1: 'Trang trí cá tính',
        content_2: 'Tỏa sáng bạn và tôi',
        content_3: 'Thể hiện sự xinh đẹp nhất của bạn với bạn bè'
      },
      third: {
        content_1: 'Không gian 3D',
        content_2: 'Xã hội không ranh giới',
        content_3: 'Trò chuyện và chơi với bạn bè trong không gian 3D'
      },
      four: {
        content_1: 'Vòng tròn bạn bè mới lạ',
        content_2: 'Luôn ở bên bạn',
        content_3: 'Trải nghiệm vòng tròn bạn bè 3D mới lạ và cảm nhận một hình thức xã hội khác'
      },
      five: {
        content_1: 'Karaoke tôi mạnh nhất',
        content_2: 'Thể hiện bản chất thực sự của bạn như một ca sĩ',
        content_3: 'Hãy khoe giọng hát của mình trong không gian KTV mát mẻ và nhận những tràng pháo tay!'
      },
      six: {
        content_1: 'Minh họa bản đồ',
        content_2: 'Bạn quyết định',
        content_3: 'Sử dụng trí tưởng tượng của bạn để tạo ra những bản đồ kỳ quái và mời bạn bè cùng ghé thăm~'
      }
    }
  },
  login: {
    content: {
      content_1: 'Chào mừng đến với Weelife',
      content_2: 'Tại đây bạn có thể thay đổi khuôn mặt, trò chuyện và kết bạn cũng như những khung cảnh thú vị để tạo nên một bức ảnh ảo độc đáo.',
      content_3: 'Đăng nhập bằng tài khoản và mật khẩu',
      content_4: 'Đăng nhập',
      content_5: 'Vui lòng nhập địa chỉ email đã đăng ký của bạn',
      content_6: 'Vui lòng nhập mật khẩu'
    },
    error: {
      content_1: 'Người dùng chưa được đăng ký',
      content_2: 'Mật khẩu đã nhập sai'
    },
    success: {
      tip_1: 'Đăng nhập thành công'
    }
  },
  music: {
    content: {
      content_1: 'Bài hát đã tải lên',
      content_2: 'Bài',
      content_3: 'Làm mới',
      content_4: 'Tải bài hát lên'
    },
    table: {
      content_1: 'Tên bài hát',
      content_2: 'Ca sĩ',
      content_3: 'Kích cỡ',
      content_4: 'Thời gian tải lên',
      content_5: 'Trạng thái tải lên',
      loading: 'Đang tải...'
    },
    logout: {
      content_1: 'Đăng xuất'
    },
    empty: {
      content_1: 'Chưa có bài hát nào được tải lên'
    },
    upload: {
      content_1: 'Quay lại trang trước',
      content_2: 'Tên bài hát',
      content_tip_2: '(Bắt buộc, không quá 20 từ)',
      content_3: 'Ca sĩ',
      content_tip_3: '(Bắt buộc, không quá 20 từ)',
      content_4: 'Tài nguyên âm nhạc',
      content_tip_4: '(Bắt buộc, file phải lớn hơn 512k và không quá 20M, chỉ hỗ trợ định dạng MP3)',
      content_5: 'Ngôn ngữ âm nhạc',
      content_tip_5: '(bắt buộc)',
      content_6: 'Xác nhận tải lên',
      content_7: 'Tôi đã đọc kỹ và đồng ý với "Quy tắc dịch vụ tải lên và chia sẻ nhạc của phòng âm bữa tiệc Weelife" sau đây',
      content_8: '(Vui lòng đọc thỏa thuận và đánh dấu vào ô đồng ý)',
      content_9: 'Nhạc đã được tải lên thành công! ! !',
      content_10: 'Tải lên không thành công, vui lòng tải lên lại! ! !'
    },
    tips: {
      content_1: 'Vui lòng nhập tên bài hát',
      content_2: 'Vui lòng nhập tên ca sĩ',
      content_3: 'Chọn tập tin',
      content_4: 'Ghi đè tập tin',
      content_5: 'Vui lòng chọn ngôn ngữ âm nhạc',
      content_6: 'Xác nhận tải lên',
      content_7: 'Tên bài hát là bắt buộc',
      content_8: 'Tên nghệ sĩ là bắt buộc',
      content_9: 'Chọn tập tin',
      content_10: 'Ghi đè tập tin',
      content_11: 'Không có tập tin nào được chọn',
      content_12: 'Tên tệp chứa các ký tự không hợp lệ (ký hiệu nửa độ rộng tiếng Anh)',
      content_13: 'Phải chọn loại nhạc',
      content_14: 'Định dạng tệp không khớp, chỉ hỗ trợ định dạng MP3'
    }
  },
  about: {
    intro: {
      title: 'Về weelife',
      content_1: 'Weelife là sản phẩm xã hội bằng giọng nói thực tế ảo được tạo ra đặc biệt dành cho giới trẻ thuộc Thế hệ genZ và đi đầu trong các xu hướng xã hội trực tuyến. Với sự hỗ trợ của công nghệ sản xuất trò chơi thế hệ tiếp theo, Weelife kết hợp hình ảnh ảo, cảnh ảo và giọng nói xã hội chất lượng cao, sự kết hợp mang lại trải nghiệm xã hội "siêu hiện diện" chưa từng có cho người dùng xã hội trên thiết bị đầu cuối di động và VR.',
      content_2: 'Trong thế giới Weelife, bạn có thể thể hiện cá tính của mình thông qua hình ảnh và quần áo tinh tế; lối chơi tương tác xã hội phong phú và thú vị, giúp việc giao lưu giữa những người bạn mới trở nên thoải mái và tự nhiên; Cùng bạn bè thông qua những trải nghiệm được chia sẻ; giọng nói chân thực và chuyển động cơ thể sống động khiến quá trình tương tác trở nên giàu cảm xúc và ấm áp hơn; bạn thậm chí có thể xây dựng khung cảnh hoặc ngôi nhà mơ ước của riêng mình để vui chơi cùng bạn bè. Mục tiêu của Weelife là cho phép mọi người dùng thu được tiếng vang của chính họ và chạm đến sự gắn kết tâm hồn của họ trong thế giới không tưởng sôi động và ấm áp này.',
      content_3: 'Weelife là một sản phẩm còn non trẻ và cần tăng trưởng liên tục để phục vụ tốt hơn cho đại đa số người dùng. Cho dù bạn là người chơi hay người sáng tạo, bất kể bạn ở đâu trên thế giới, chúng tôi mong muốn lắng nghe ý kiến ​​và đề xuất của bạn cũng như hợp tác với chúng tôi để xây dựng một điều không tưởng lý tưởng.'
    },
    culture: {
      title: 'Văn hóa doanh nghiệp',
      content_1: 'Công khai và minh bạch',
      content_2: 'Khiêm tốn đơn giản',
      content_3: 'Thử thách đến cùng cực',
      content_4: 'Khởi nghiệp bằng thực lực'
    },
    welfare: {
      title: 'Phúc lợi đãi ngộ'
    },
    contact: {
      title: 'Liên hệ với chúng tôi',
      content_1: 'Địa chỉ: Phòng 402, Tòa nhà Cần Thiên, Số 238 Đại lộ Tây Trung Sơn, Quận Thiên Hà, thành phố Quảng Châu',
      content_2: 'ĐT: 020-66837100',
      content_3: 'Email nhân sự:'
    }
  },
  recharge: {
    header: 'Nạp tiền tài khoản',
    title: {
      title_1: 'Vui lòng nhập ID tài khoản của bạn',
      title_2: 'Chọn phương thức nạp tiền',
      title_3: 'Chọn số tiền nạp'
    },
    box_1: {
      uid: 'ID tài khoản',
      placeholder: 'Vui lòng nhập ID tài khoản của bạn',
      btn: 'Xác minh',
      switchText: 'Chuyển đổi tài khoản'
    },
    bottom: {
      price: 'Số tiền phải trả',
      pay: 'Thanh toán'
    },
    help: {
      header: 'Làm cách nào để kiểm tra ID tài khoản?',
      title_1: 'Mở ứng dụng Weelife và tìm trang [Của tôi]',
      title_2: 'Nhấp vào nút [Sao chép] bên cạnh ID của bạn',
      confirm_btn: 'Biết rồi!'
    },
    info: {
      header: 'Chi tiết đặt hàng',
      title: 'Vui lòng xác nhận thông tin nạp tiền của bạn',
      nickname: 'Biệt danh',
      recharge: 'Số tiền nạp',
      total_price: 'Tổng giá',
      cancel: 'Hủy bỏ',
      confirm: 'Xác nhận'
    },
    error: {
      uid_not: 'ID tài khoản này không tồn tại'
    },
    order: {
      submit_loading: 'Đơn hàng đang được gửi...',
      inquire_loading: 'Đang tiến hành yêu cầu đặt hàng...',
      success: 'Thanh toán thành công',
      success_info: 'Nạp tiền đơn hàng thành công! Cảm ơn bạn đã sử dụng và ủng hộ, vui lòng quay lại weelife kiểm tra',
      timed_out: 'Hết thời gian truy vấn đơn hàng',
      timed_out_info: 'Đã hết thời gian truy vấn đơn hàng, nhấn Xác nhận để tiếp tục truy vấn',
      timed_out_info_two: 'Đã hết thời gian truy vấn đơn hàng, vui lòng quay lại weelife - Thông báo hệ thống - liên hệ CSKH để được giải đáp'
    }
  },
  language: {
    zh: '中文',
    'zh-Hant': '中文繁体',
    en: 'English',
    ar: 'العربية',
    in: 'Indonesia',
    ms: 'Melayu',
    tl: 'Filipino',
    vi: 'Tiếng Việt',
    th: 'ไทย'
  },
  download: {
    btn: 'Tải xuống ngay bây giờ'
  },
  popup: {
    cancel: 'Hủy bỏ',
    confirm: 'Xác nhận'
  }
}
