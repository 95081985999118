export default {
  route: {
    home: 'Tahanan',
    music: 'Pag-upload ng Musika',
    about: 'Tungkol sa Amin',
    upload: 'Mag-upload',
    login: 'Mag-login',
    privacy: 'Patakaran sa Privacy',
    userve: 'Kasunduan ng Gumagamit',
    recharge: 'Mag-recharge',
    udesk: 'Serbisyo sa customer',
    quickGuide: 'Mabilis na Gabay sa weelife',
    roomRewards: 'Sumali sa silid para sa gantimpala',
    updateContent: 'I-UPDATE ANG NILALAMAN',
    friendSpace: 'Gantimpala ng Puesto ng Kaibigan',
    userGroup: 'Opisyal na Grupo ng Mga Gumagamit',
    quickStartGuide: 'Mabilis na Gabay sa weelife',
    ktvParty: 'K-Singing Party'
  },
  webMeta: {
    title: 'weelife',
    titleCompany: 'Xinyan',
    description: 'Ang Weelife ay isang virtual reality voice social product na partikular na ginawa para sa mga kabataan ng Generation Z at nasa harapan ng online social trends. Sa tulong ng next-generation game production techniques, pinagsasama nito ang high-quality game-like virtual images, virtual scenes, at voice social interaction upang magbigay ng walang kapantay na "super immersive" social experience sa mobile at VR platforms. Sa mundo ng Weelife, maaari mong ipakita ang iyong personal na pagkakakilanlan sa pamamagitan ng magaganda at angkop na mga imahe at kasuotan; ang mga masaya at kapana-panabik na social interaction gameplay ay nagbibigay-daan sa isang komportable at natural na social ice-breaking sa pagitan ng mga bagong kaibigan; iba‘t ibang social scenes, mula sa malugod at romantikong hanggang sa kapana-panabik at nakakaaliw, na nagtataguyod ng mga koneksyon sa mga kaibigan sa pamamagitan ng mga pinagsaluhang karanasan; tunay na boses at buhay na katawan na mga galaw ay nagpapadama ng higit na emosyon at init sa proseso ng interaksyon; maaari ring bumuo ng iyong mga pangarap na mga eksena o tahanan at maglaro kasama ang mga kaibigan. Ang layunin ng Weelife ay upang ang bawat gumagamit sa masigla at maaliwalas na utopia na ito ay makakuha ng echo ng kanilang sariling boses at makatagpo ng koneksyon ng kaluluwa. Ang Weelife ay isang batang produkto na patuloy na nangangailangan ng paglago upang mas mahusay na maglingkod sa mga gumagamit. Kung ikaw man ay isang manlalaro o isang tagalikha, saan ka man sa mundo, inaasahan naming marinig ang iyong mga opinyon at mungkahi, at magtulungan upang buuin ang perpektong utopia.',
    descriptionCompany: 'Ang Weelife ay isang virtual reality voice social product na partikular na ginawa para sa mga kabataan ng Generation Z at nasa harapan ng online social trends. Sa tulong ng next-generation game production techniques, pinagsasama nito ang high-quality game-like virtual images, virtual scenes, at voice social interaction upang magbigay ng walang kapantay na "super immersive" social experience sa mobile at VR platforms. Sa mundo ng Weelife, maaari mong ipakita ang iyong personal na pagkakakilanlan sa pamamagitan ng magaganda at angkop na mga imahe at kasuotan; ang mga masaya at kapana-panabik na social interaction gameplay ay nagbibigay-daan sa isang komportable at natural na social ice-breaking sa pagitan ng mga bagong kaibigan; iba’t ibang social scenes, mula sa malugod at romantikong hanggang sa kapana-panabik at nakakaaliw, na nagtataguyod ng mga koneksyon sa mga kaibigan sa pamamagitan ng mga pinagsaluhang karanasan; tunay na boses at buhay na katawan na mga galaw ay nagpapadama ng higit na emosyon at init sa proseso ng interaksyon; maaari ring bumuo ng iyong mga pangarap na mga eksena o tahanan at maglaro kasama ang mga kaibigan. Ang layunin ng Weelife ay upang ang bawat gumagamit sa masigla at maaliwalas na utopia na ito ay makakuha ng echo ng kanilang sariling boses at makatagpo ng koneksyon ng kaluluwa.',
    keywords: 'Weelife;Weelife app;Weelifeapp;weelife;weelife app;weelifeapp;WeelifeApp',
    keywordsCompany: 'Xinyan;Opisyal na Website ng Xinyan;Guangzhou Xinyan;Xinyan Information;Guangzhou Xinyan Information;Xinyan Information Technology;Guangzhou Xinyan Information Technology;Guangzhou Xinyan Information Technology Co., Ltd.;newlang;Opisyal na Website ng newlang;newlang app;newlang App;Newlang'
  },
  errorPage404: {
    errMsg404: 'Error sa network request, hindi mahanap ang mapagkukunan!',
    btnText: 'Buksan ang App',
    MDialog: {
      headerText: 'I-scan ang QR code para i-download ang weelife App',
      btnText: 'I-download ang App'
    }
  },
  public: {
    downloading: 'Nagda-download...',
    wait: 'Pakiusap maghintay...',
    openFail: 'Nabigong buksan ang APP, subukan muli'
  },
  footer: {
    priv: 'Patakaran sa Privacy',
    userve: 'Kasunduan ng Gumagamit',
    contact: 'Makipag-ugnay sa Amin',
    email: 'Email para sa Pakikipagtulungan'
  },
  homePage: {
    content: {
      second: {
        content_1: 'Personal na Pagsusuot',
        content_2: 'Magningning ka',
        content_3: 'Ipakita ang iyong pinakamagandang sarili sa iyong mga kaibigan'
      },
      third: {
        content_1: '3D Space',
        content_2: 'Social na Walang Hanggan',
        content_3: 'Makipag-chat at maglaro sa mga kaibigan sa 3D space'
      },
      four: {
        content_1: 'Kakaibang Social Circle',
        content_2: 'Palaging Kasama',
        content_3: 'Tuklasin ang kakaibang 3D social circle, maranasan ang kakaibang uri ng social interaction'
      },
      five: {
        content_1: 'K-Singing Ako ang Pinakamahusay',
        content_2: 'Ipakita ang iyong pagkamusikal',
        content_3: 'Magkaroon ng pagkakataon na kumanta sa mga kamangha-manghang KTV space at makatanggap ng papuri!'
      },
      six: {
        content_1: 'Mapa na may Larawan',
        content_2: 'Ikaw ang Boss',
        content_3: 'Gamitin ang iyong imahinasyon upang lumikha ng mga natatanging mapa at imbitahan ang mga kaibigan na maglakbay!'
      }
    }
  },
  login: {
    content: {
      content_1: 'Maligayang pagdating sa Weelife',
      content_2: 'Dito makikita ang pagpapasadya ng mukha, pag-chat at pakikipagkaibigan, at magagandang eksena upang lumikha ng natatanging virtual na pagkakakilanlan',
      content_3: 'Pag-login gamit ang Email at Password',
      content_4: 'Mag-login',
      content_5: 'Pakiusap ilagay ang iyong nakarehistrong email',
      content_6: 'Pakiusap ilagay ang password'
    },
    error: {
      content_1: 'Hindi Nakarehistro ang User',
      content_2: 'Mali ang password na ipinasok'
    },
    success: {
      tip_1: 'Matagumpay na naka-login'
    }
  },
  music: {
    content: {
      content_1: 'Nai-upload na mga Kanta',
      content_2: 'Mga kanta',
      content_3: 'I-refresh',
      content_4: 'Mag-upload ng Kanta'
    },
    table: {
      content_1: 'Pangalan ng Kanta',
      content_2: 'Artista',
      content_3: 'Laki',
      content_4: 'Panahon ng Pag-upload',
      content_5: 'Katayuan ng Pag-upload',
      loading: 'Naglo-load...'
    },
    logout: {
      content_1: 'Mag-logout'
    },
    empty: {
      content_1: 'Walang Nai-upload na Kanta'
    },
    upload: {
      content_1: 'Bumalik sa Nakaraang Pahina',
      content_2: 'Pangalan ng Kanta',
      content_tip_2: ' (Kailangan, hindi hihigit sa 20 na mga titik)',
      content_3: 'Artista',
      content_tip_3: ' (Kailangan, hindi hihigit sa 20 na mga titik)',
      content_4: 'Mapagkukunan ng Musika',
      content_tip_4: ' (Kailangan, file na mas malaki sa 512k at hindi hihigit sa 20M, sumusuporta lamang sa MP3 format)',
      content_5: 'Wika ng Musika',
      content_tip_5: ' (Kailangan)',
      content_6: 'Kumpirmahin ang Pag-upload',
      content_7: 'Ako ay nagbasa at sumasang-ayon sa mga sumusunod na "Mga Patakaran ng Serbisyo para sa Pag-upload at Pagbabahagi ng Musika sa Weelife Party Room"',
      content_8: ' (Pakiusap basahin ang kasunduan at i-check ang pag-sang-ayon)',
      content_9: 'Matagumpay na nai-upload ang musika!!!',
      content_10: 'Nabigong mag-upload, pakiusap subukang muli!!!'
    },
    tips: {
      content_1: 'Pakiusap ilagay ang pangalan ng kanta',
      content_2: 'Pakiusap ilagay ang pangalan ng artista',
      content_3: 'Pumili ng file',
      content_4: 'Palitan ang file',
      content_5: 'Piliin ang wika ng musika',
      content_6: 'Kumpirmahin ang Pag-upload',
      content_7: 'Kinakailangan na ilagay ang pangalan ng kanta',
      content_8: 'Kinakailangan na ilagay ang pangalan ng artista',
      content_9: 'Pumili ng file',
      content_10: 'Palitan ang file',
      content_11: 'Walang napiling file',
      content_12: 'May mga hindi wastong karakter sa pangalan ng file (mga half-width na simbolo ng Ingles)',
      content_13: 'Kinakailangan na pumili ng uri ng musika',
      content_14: 'Hindi tugma ang format ng file, sumusuporta lamang sa MP3 format'
    }
  },
  about: {
    intro: {
      title: 'Tungkol sa Weelife',
      content_1: 'Ang Weelife ay isang virtual reality voice social product na partikular na ginawa para sa mga kabataan ng Generation Z at nasa harapan ng online social trends. Sa tulong ng next-generation game production techniques, pinagsasama nito ang high-quality game-like virtual images, virtual scenes, at voice social interaction upang magbigay ng walang kapantay na "super immersive" social experience.',
      content_2: 'Sa mundo ng Weelife, maaari mong ipakita ang iyong personal na pagkakakilanlan sa pamamagitan ng magaganda at angkop na mga imahe at kasuotan; ang mga masaya at kapana-panabik na social interaction gameplay ay nagbibigay-daan sa isang komportable at natural na social ice-breaking sa pagitan ng mga bagong kaibigan; iba‘t ibang social scenes, mula sa malugod at romantikong hanggang sa kapana - panabik at nakakaaliw, na nagtataguyod ng mga koneksyon sa mga kaibigan sa pamamagitan ng mga pinagsaluhang karanasan; tunay na boses at buhay na katawan na mga galaw ay nagpapadama ng higit na emosyon at init sa proseso ng interaksyon; maaari ring bumuo ng iyong mga pangarap na mga eksena o tahanan at maglaro kasama ang mga kaibigan.Ang layunin ng Weelife ay upang ang bawat gumagamit sa masigla at maaliwalas na utopia na ito ay makakuha ng echo ng kanilang sariling boses at makatagpo ng koneksyon ng kaluluwa.',
      content_3: 'Ang Weelife ay isang batang produkto na patuloy na nangangailangan ng paglago upang mas mahusay na maglingkod sa mga gumagamit. Kung ikaw man ay isang manlalaro o isang tagalikha, saan ka man sa mundo, inaasahan naming marinig ang iyong mga opinyon at mungkahi, at magtulungan upang buuin ang perpektong utopia.'
    },
    culture: {
      title: 'Kultura ng Kumpanya',
      content_1: 'Bukas at Transparent',
      content_2: 'Simple at Mapagpakumbaba',
      content_3: 'Magkaroon ng Pagsubok sa Pinakamataas',
      content_4: 'Magsikap at Mag-entrepreneur'
    },
    welfare: {
      title: 'Mga Benepisyo at Pabahay'
    },
    contact: {
      title: 'Makipag-ugnay sa Amin',
      content_1: 'Address: 402, Qintian Building, No. 238, Zhongshan Avenue West, Tianhe District, Guangzhou',
      content_2: 'Telepono: 020-66837100',
      content_3: 'HR Email:'
    }
  },
  recharge: {
    header: 'Recharge ng Account',
    title: {
      title_1: 'Ilagay ang Iyong Account ID',
      title_2: 'Pumili ng Pamamaraan ng Pag-recharge',
      title_3: 'Pumili ng Halaga ng Pag-recharge'
    },
    box_1: {
      uid: 'Account ID',
      placeholder: 'Ilagay ang Iyong Account ID',
      btn: 'I-verify',
      switchText: 'Palitan ang Account'
    },
    bottom: {
      price: 'Dapat Bayaran',
      pay: 'Magbayad'
    },
    help: {
      header: 'Paano Tingnan ang Account ID?',
      title_1: 'Buksan ang Weelife app, hanapin ang pahina na 【Aking】',
      title_2: 'Mag-click sa button na 【Kopyahin】 sa tabi ng iyong ID',
      confirm_btn: 'Naiintindihan!'
    },
    info: {
      header: 'Detalye ng Order',
      title: 'Pakitingnan ang Iyong Recharge na Impormasyon',
      nickname: 'Palayaw',
      recharge: 'Halaga ng Recharge',
      total_price: 'Kabuuang Presyo',
      cancel: 'Kanselahin',
      confirm: 'Kumpirmahin'
    },
    error: {
      uid_not: 'Ang Account ID na ito ay hindi umiiral'
    },
    order: {
      submit_loading: 'Pag-submit ng Order...',
      inquire_loading: 'Nagsusuri ng order...',
      success: 'Matagumpay ang pagbabayad',
      success_info: 'Matagumpay ang pag-recharge ng order! Salamat sa iyong paggamit at suporta, mangyaring bumalik sa weelife upang tingnan.',
      timed_out: 'Nag-timeout ang pag-query ng order',
      timed_out_info: 'Nag-timeout ang pag-query ng order, i-click ang kumpirmahin upang ipagpatuloy ang pag-query.',
      timed_out_info_two: 'Nag-timeout ang pag-query ng order, mangyaring bumalik sa weelife - system notification - upang makipag-ugnayan sa customer service.'
    }
  },
  language: {
    zh: '中文',
    'zh-Hant': '中文繁体',
    en: 'English',
    ar: 'العربية',
    in: 'Indonesia',
    ms: 'Melayu',
    tl: 'Filipino',
    vi: 'Tiếng Việt',
    th: 'ไทย'
  },
  download: {
    btn: 'I-download Ngayon'
  },
  popup: {
    cancel: 'Kanselahin',
    confirm: 'Kumpirmahin'
  }
}
