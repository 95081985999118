<template>
  <div class="box-clamp">
    <div ref="boxClampDescRef" :class="{ 'box-clamp__hidden': !expande }">
      {{ content }}</div>
    <div class="box-clamp__moreBtn" v-if="isOver" @click="expandeClick"><span>{{ expande ? 'pack up' : 'more' }}</span>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, toRefs, onMounted, ref, Ref, PropType, watch } from 'vue'
interface IBoxClampState {
  expande: Ref<boolean>
  isOver: Ref<boolean>
  content: Ref<string>
  expandeClick: () => void
  boxClampDescRef: Ref<HTMLElement | undefined>
}
export default defineComponent({
  props: {
    value: {
      type: String as PropType<string>,
      required: true
    }
  },
  name: 'BoxClamp',
  setup(
    props: Readonly<{
      value: string
    }>
  ): IBoxClampState {
    const boxClampDescRef = ref<HTMLElement>()
    const state = reactive({
      expande: false,
      isOver: false,
      content: ''
    })

    watch(() => props.value, (val) => {
      state.content = val
    }, { immediate: true })

    const expandeClick = () => {
      state.expande = !state.expande
    }

    onMounted(() => {
      if (boxClampDescRef.value) {
        state.isOver =
          boxClampDescRef.value.offsetHeight < boxClampDescRef.value.scrollHeight
      }
    })

    return {
      boxClampDescRef,
      ...toRefs(state),
      expandeClick
    }
  }
})
</script>
<style lang='scss' scoped>
@include b(box-clamp) {
  color: #333355;
  white-space: pre-wrap;

  @include e(hidden) {
    @include box-clamp(2)
  }

  @include e(moreBtn) {
    cursor: pointer;
    text-align: end;
    font-size: 28px;
    font-weight: 400;
    color: #7A6ED6;
    margin-top: 5px;

    span {
      border-radius: 20px;
      border: 2px solid #D3D7E1;
      padding: 2px;
    }
  }
}
</style>
