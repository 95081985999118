export default {
  route: {
    home: 'الصفحة الرئيسية',
    music: 'تحميل الموسيقى',
    about: 'معلومات عنا',
    upload: 'التحميل',
    login: 'تسجيل الدخول',
    privacy: 'سياسة خصوصية',
    userve: 'اتفاقية مستخدم',
    recharge: ' الشحن',
    udesk: 'خدمة العملاء',
    quickGuide: 'weelife قم سريعا بالانضمام',
    roomRewards: 'Join room for rewards',
    updateContent: 'UPDATE CONTENT',
    friendSpace: 'مكافآت مساحة الأصدقاء',
    userGroup: 'المجموعة الرسمية للمستخدمين',
    quickStartGuide: 'weelife قم سريعا بالانضمام',
    ktvParty: 'حزب الكاريوكي'
  },
  webMeta: {
    title: 'weelife',
    titleCompany: 'newlang',
    description: 'Weelife is a cutting-edge virtual reality voice social product tailored for Generation Z. Combining high-quality gamified virtual elements with voice social interactions, it offers an unparalleled ultra-immersive social experience on both mobile and VR platforms. In Weelife world, users express their personalities through stylish avatars. Rich social interaction features make connecting with new friends easy and enjoyable.',
    descriptionCompany: 'Weelife is a cutting-edge virtual reality voice social product tailored for Generation Z. Combining high-quality gamified virtual elements with voice social interactions, it offers an unparalleled ultra-immersive social experience on both mobile and VR platforms. In Weelife world, users express their personalities through stylish avatars. Rich social interaction features make connecting with new friends easy and enjoyable.',
    keywords: 'Weelife;Weelife app;Weelifeapp;weelife;weelife app;weelifeapp;WeelifeApp',
    keywordsCompany: '新言;新言官网;广州新言;新言信息;广州新言信息;新言信息科技;广州新言信息科技;广州新言信息科技有限公司;newlang;newlang官网;newlang app;newlang App;Newlang'
  },
  errorPage404: {
    errMsg404: 'حدث خطأ في طلب الشبكة، لم يتم العثور على الموارد',
    btnText: 'افتح التطبيق',
    MDialog: {
      headerText: 'امسح رمز الاستجابة السريعة لتنزيل تطبيق Weelife',
      btnText: 'تنزيل التطبيق'
    }
  },
  public: {
    downloading: 'جار التنزيل ...',
    wait: 'يرجى الإنتظار...',
    openFail: 'فشل فتح التطبيق، حاول مرة أخرى'
  },
  footer: {
    priv: 'سياسة الخصوصية',
    userve: 'إتفاقية المستخدم',
    contact: 'التواصل معنا',
    email: 'البريد الإلكتروني'
  },
  homePage: {
    content: {
      second: {
        content_1: 'المظهر الشخصي',
        content_2: 'تألق',
        content_3: 'أظهر أجمل ما لديك لأصدقائك'
      },
      third: {
        content_1: 'مساحة ثلاثية الأبعاد',
        content_2: 'تواصل إجتماعي بلا حدزد',
        content_3: 'دردش والعب مع الأصدقاء في مساحة ثلاثية الأبعاد'
      },
      four: {
        content_1: 'دائرة الأصدقاء',
        content_2: 'معك دائماً',
        content_3: 'استمتع بتجربة دائرة الأصدقاء ثلاثية الأبعاد، واشعر بتواصل إجتماعي مختلف'
      },
      five: {
        content_1: 'أنا الأقوى في الكاريوكي',
        content_2: 'أطلق العنان لصوتك الملكي',
        content_3: 'أظهر صوتك الغنائي الجميل في مساحة كاريوكي وأحصل على الهتافات المشجعة'
      },
      six: {
        content_1: 'خريطة توضيحية',
        content_2: 'أنت صاحب القرار',
        content_3: 'استخدم مخيلتك لإنشاء خرائط مذهلة، وأدعو الأصدقاء لزيارتها معاً'
      }
    }
  },
  login: {
    content: {
      content_1: 'مرحباً بكم في Weelife',
      content_2: 'هنا يمكنك تغيير الملامح، والدردشة وتكوين صداقات، وإنشاء مشاهد رائعة لصورة إفتراضية فريدة من نوعها.',
      content_3: 'تسجيل الدخول بإستخدام الحساب وكلمة المرور',
      content_4: 'تسجيل الدخول',
      content_5: 'الرجاء إدخال عنوان بريدك الإلكتروني المسجل',
      content_6: 'الرجاء إدخال كلمة المرور'
    },
    error: {
      content_1: 'المستخدم غير مسجل',
      content_2: 'كلمة المرور التي تم إدخالها خاطئة'
    },
    success: {
      tip_1: 'تم تسجيل الدخول بنجاح'
    }
  },
  music: {
    content: {
      content_1: 'تم تحميل الأغنية',
      content_2: 'الرئيسية',
      content_3: 'تحديث',
      content_4: 'تحميل الأغنية'
    },
    table: {
      content_1: 'اسم الأغنية',
      content_2: 'الفنان',
      content_3: 'الحجم',
      content_4: 'وقت التحميل',
      content_5: 'حالة التحميل',
      loading: 'جار التحميل ...'
    },
    logout: {
      content_1: 'تسجيل الخروج'
    },
    empty: {
      content_1: 'لم يتم تحميل أي أغنية حتى الآن'
    },
    upload: {
      content_1: 'العودة إلى الصفحة السابقة',
      content_2: 'اسم الأغنية',
      content_tip_2: '(إجباري، لا يزيد عن 20 كلمة)',
      content_3: 'الفنان',
      content_tip_3: '(إجباري، لا يزيد عن 20 كلمة)',
      content_4: 'موارد الموسيقى',
      content_tip_4: '(إجباري، يجب أن يكون حجم الملف أكبر من 512 كيلو بايت ولا يزيد عن 20 ميجا بايت، وتنسيق MP3 فقط)',
      content_5: 'لغة الأغاني',
      content_tip_5: '(إجباري)',
      content_6: 'تأكيد التحميل',
      content_7: 'لقد قرأت بعناية ووافقت على "قواعد خدمة تحميل ومشاركة الموسيقى في غرفة كاريوكي Weelife" ',
      content_8: '(يُرجى قراءة الإتفاقية والتأشير على المربع للموافقة)',
      content_9: 'تم تحميل الموسيقى بنجاح',
      content_10: 'فشل التحميل، يرجى التحميل مرة أخرى'
    },
    tips: {
      content_1: 'الرجاء إدخال اسم الأغنية',
      content_2: 'الرجاء إدخال اسم الفنان',
      content_3: 'اختر الملف',
      content_4: 'إستبدال الملف',
      content_5: 'الرجاء تحديد لغة الأغاني',
      content_6: 'تأكيد التحميل',
      content_7: 'يجب إدخال اسم الأغنية',
      content_8: 'يجب إدخال اسم الفنان',
      content_9: 'اختر الملف',
      content_10: 'إستبدال الملف',
      content_11: 'لم يتم إختيار اي ملف',
      content_12: 'اسم الملف مخالف للقوانين',
      content_13: 'يجب إختيار نوع الموسيقى',
      content_14: 'تنسيق الملف غير متوافق، يدعم تنسيق MP3 فقط'
    }
  },
  about: {
    intro: {
      title: 'حول Weelife',
      content_1: 'Weelife هو منتج إجتماعي للدردشة الصوتية للواقع الإفتراضي، تم إنشاؤه خصيصاً للشباب من جيل التسعينات والألفية، وهو في طليعة الإتجاهات الإجتماعية عبر شبكة الإنترنت، وبدعم تكنولوجيا إنتاج الألعاب للجيل القادم، فهو يجمع بين الصور والمشاهد الإفتراضية عالية الجودة، والدردشة الصوتية لتوفير تجربة إجتماعية غير مسبوقة "ذات حضور فائق" لمستخدمي وسائل التواصل الإجتماعي على الأجهزة المحمولة والواقع الإفتراضي.',
      content_2: 'في عالم Weelife، يمكنك التعبير عن شخصيتك من خلال الأفتار الخاص بك، والملابس الرائعة. لعبة تواصل إجتماعي مثيرة للإهتمام، مما يجعل كسر الجمود الإجتماعي بين الأصدقاء الجدد مريحاً وطبيعياً؛ مشاهد متنوعة، إما دافئة أو رومانسية أو مثيرة، تبني الروابط مع الأصدقاء من خلال التجارب المشتركة؛ الدردشة الصوتية وحركات الأفتار الحية تجعل عملية التواصل أكثر واقعية وعاطفية؛ يمكنك حتى إنشاء مشهد أحلامك أو منزلاً للتواجد به مع الأصدقاء. هدف Weelife هو السماح لكل مستخدم  للإنطلاق بوحي خياله وإخراج إبداعه والعيش في واقع إفتراضي أقرب للحقيقة.',
      content_3: 'Weelife هو منتج لا يزال جديداً، ويحتاج إلى تطوير مستمر لتقديم خدمة أفضل لغالبية المستخدمين. سواء كنت مستخدماً أو مبدعاً، بغض النظر عن مكان تواجدك في العالم، فإننا نتطلع إلى الإستماع إلى آرائكم وإقتراحاتكم والعمل معنا لجعل التطبيق مثالي.'
    },
    culture: {
      title: 'ثقافة الشركة',
      content_1: 'مفتوحة وشفافة',
      content_2: 'بسيطة ومتواضعة',
      content_3: 'تحدي إلى أقصى الحدود ',
      content_4: 'ابدأ مشروعاً تجارياً بالعمل الجاد'
    },
    welfare: {
      title: 'الفوائد'
    },
    contact: {
      title: 'التواصل معنا',
      content_1: 'العنوان: مدينة جوانزو، منطقة تيانخا، شارع جونجشان رقم 238، مبنى كينتيان، غرفة 402 ',
      content_2: 'هاتف: 020-66837100',
      content_3: 'البريد الإلكتروني لإدارة الموارد البشرية：'
    }
  },
  recharge: {
    header: 'شحن الحساب',
    title: {
      title_1: 'الرجاء إدخال معرف الحساب الخاص بك',
      title_2: 'اختر طريقة الشحن',
      title_3: 'حدد مبلغ الشحن'
    },
    box_1: {
      uid: 'معرف/ID الحساب',
      placeholder: 'الرجاء إدخال معرف الحساب الخاص بك',
      btn: 'التحقق',
      switchText: 'تبديل الحساب'
    },
    bottom: {
      price: 'المبالغ المستحقة الدفع',
      pay: 'الدفع'
    },
    help: {
      header: 'كيفية التحقق من معرف الحساب؟',
      title_1: 'افتح تطبيق (Weelife) وانقر على واجهة (الخاص بي)',
      title_2: 'انقر فوق (نسخ) بجوار المعرف الخاص بك',
      confirm_btn: 'حسناً'
    },
    info: {
      header: 'رسالة تفاصيل الطلب',
      title: 'يرجى تأكيد معلومات الشحن الخاصة بك',
      nickname: 'الاسم المستعار',
      recharge: 'مبلغ الشحن',
      total_price: 'التكلفة الإجمالية',
      cancel: 'إلغاء',
      confirm: 'تأكيد'
    },
    error: {
      uid_not: 'معرف هذا الحساب غير موجود'
    },
    order: {
      submit_loading: 'جاري تقديم الطلب ...',
      inquire_loading: 'انتهاء وقت الاستعلام عن الطلب',
      success: 'تم الدفع',
      success_info: 'تم الشحن بنجاح، شكراً جزيلاً. يرجى الرجوع إلى weelife للتحقق',
      timed_out: 'انتهت مهلة الاستفسار عن الطلب',
      timed_out_info: 'انتهت مهلة الاستفسار عن الطلب، انقر فوق تأكيد لمواصلة الاستفسار',
      timed_out_info_two: 'انتهت مهلة الاستفسار عن الطلب، يرجى الرجوع إلى weelife - إشعارات النظام - الاتصال بخدمة العملاء للاستفسار.'
    }
  },
  language: {
    zh: '中文',
    'zh-Hant': '中文繁体',
    en: 'English',
    ar: 'العربية',
    in: 'Indonesia',
    ms: 'Melayu',
    tl: 'Filipino',
    vi: 'Tiếng Việt',
    th: 'ไทย'
  },
  download: {
    btn: 'التنزيل الآن'
  },
  popup: {
    cancel: 'إلغاء',
    confirm: 'تأكيد'
  }
}
