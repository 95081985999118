export default {
  route: {
    home: '首页',
    music: '音乐上传',
    about: '关于我们',
    upload: '上传',
    login: '登陆',
    privacy: '隐私协议',
    userve: '用户协议',
    recharge: '充值',
    udesk: '客服服务',
    quickGuide: 'weelife快速入门指南',
    roomRewards: 'Join room for rewards',
    updateContent: 'UPDATE CONTENT',
    friendSpace: '好友空间奖励',
    userGroup: '官方用户群',
    quickStartGuide: 'weelife快速入门指南',
    ktvParty: 'K歌派对'
  },
  webMeta: {
    title: 'weelife',
    titleCompany: '新言',
    description: 'Weelife是一款专为Z世代年轻人打造、走在线上社交潮流前沿的虚拟现实语音社交产品，在次世代游戏制作工艺的加持下，将高品质的游戏化虚拟形象、虚拟场景与语音社交相结合，在移动端、VR端给社交用户带来前所未有的“超临场”社交体验。在Weelife世界中，可以通过精美的形象和服饰搭配来彰显自己的个性人设；丰富、有趣的社交互动玩法，让新朋友之间的社交破冰舒适而自然；多样的社交场景，或温馨浪漫或惊险刺激，与朋友在共同的经历中建立羁绊；真实的语音、生动的肢体动作使互动过程更具情感和温度；甚至可以建造自己的梦想场景或家园与朋友一起游玩。Weelife的目标是，让每一位用户在这个充满活力和温情的乌托邦中，都可以收获到自己声音的回响，遇到灵魂的羁绊。Weelife是一款尚为年轻的产品，需要不断的成长才能更好的服务于广大用户。无论您是玩家还是创作者，无论您在世界何处，我们都期待聆听您的意见和建议，与我们一起共建理想中的乌托邦。',
    descriptionCompany: 'Weelife是一款专为Z世代年轻人打造、走在线上社交潮流前沿的虚拟现实语音社交产品，在次世代游戏制作工艺的加持下，将高品质的游戏化虚拟形象、虚拟场景与语音社交相结合，在移动端、VR端给社交用户带来前所未有的“超临场”社交体验。在Weelife世界中，可以通过精美的形象和服饰搭配来彰显自己的个性人设；丰富、有趣的社交互动玩法，让新朋友之间的社交破冰舒适而自然；多样的社交场景，或温馨浪漫或惊险刺激，与朋友在共同的经历中建立羁绊；真实的语音、生动的肢体动作使互动过程更具情感和温度；甚至可以建造自己的梦想场景或家园与朋友一起游玩。Weelife的目标是，让每一位用户在这个充满活力和温情的乌托邦中，都可以收获到自己声音的回响，遇到灵魂的羁绊。Weelife是一款尚为年轻的产品，需要不断的成长才能更好的服务于广大用户。无论您是玩家还是创作者，无论您在世界何处，我们都期待聆听您的意见和建议，与我们一起共建理想中的乌托邦。',
    keywords: 'Weelife;Weelife app;Weelifeapp;weelife;weelife app;weelifeapp;WeelifeApp',
    keywordsCompany: '新言;新言官网;广州新言;新言信息;广州新言信息;新言信息科技;广州新言信息科技;广州新言信息科技有限公司;newlang;newlang官网;newlang app;newlang App;Newlang'
  },
  errorPage404: {
    errMsg404: '网络请求错误,未找到该资源!',
    btnText: '打开App',
    MDialog: {
      headerText: '扫码下载weelife App',
      btnText: '下载App'
    }
  },
  public: {
    downloading: '下载中...',
    wait: '请稍等...',
    openFail: '打开APP失败,尝试重新唤起'
  },
  footer: {
    priv: '隐私政策',
    userve: '用户协议',
    contact: '联系我们',
    email: '合作邮箱'
  },
  homePage: {
    content: {
      second: {
        content_1: '个性装扮',
        content_2: '闪亮你我',
        content_3: '将最美的自己展现给小伙伴们'
      },
      third: {
        content_1: '3D空间',
        content_2: '社交无界',
        content_3: '与小伙伴们一起在3d空间聊天和玩耍'
      },
      four: {
        content_1: '新奇朋友圈',
        content_2: '陪伴常在',
        content_3: '体验新奇的3D朋友圈，感受不一样的社交形态'
      },
      five: {
        content_1: 'K歌我最强',
        content_2: '尽显歌王本色',
        content_3: '在炫酷的KTV空间一展歌喉，收获喝彩！'
      },
      six: {
        content_1: '地图配图',
        content_2: '你来做主',
        content_3: '发挥想象力，创作奇思妙想的地图，邀请好友一起游览~'
      }
    }
  },
  login: {
    content: {
      content_1: '欢迎来到Weelife',
      content_2: '这里有捏脸换装、聊天交友、酷炫场景，打造与众不同的虚拟形象',
      content_3: '账号密码登录',
      content_4: '登录',
      content_5: '请输入已注册的邮箱',
      content_6: '请输入密码'
    },
    error: {
      content_1: '用户未注册',
      content_2: '输入的密码错误'
    },
    success: {
      tip_1: '登录成功'
    }
  },
  music: {
    content: {
      content_1: '已上传歌曲',
      content_2: '首',
      content_3: '刷新',
      content_4: '上传歌曲'
    },
    table: {
      content_1: '歌曲名称',
      content_2: '歌手',
      content_3: '大小',
      content_4: '上传时间',
      content_5: '上传状态',
      loading: '加载中...'
    },
    logout: {
      content_1: '退出登录'
    },
    empty: {
      content_1: '暂无上传歌曲'
    },
    upload: {
      content_1: '返回上一页',
      content_2: '歌曲名称',
      content_tip_2: '（必填，不超过20字）',
      content_3: '歌手',
      content_tip_3: '（必填，不超过20字）',
      content_4: '音乐资源',
      content_tip_4: '（必填，文件需大于512k且不超过20M，仅支持MP3格式）',
      content_5: '音乐语种',
      content_tip_5: '（必选）',
      content_6: '确定上传',
      content_7: '我已经认真阅读并同意以下《Weelife派对房音乐上传分享服务规则》',
      content_8: '（请阅读协议并勾选同意）',
      content_9: '上传音乐成功！！！',
      content_10: '上传失败,请重新上传！！！'
    },
    tips: {
      content_1: '请输入歌曲名称',
      content_2: '请输入歌手名',
      content_3: '选择文件',
      content_4: '覆盖文件',
      content_5: '请选择音乐语种',
      content_6: '确定上传',
      content_7: '必须输入歌曲名称',
      content_8: '必须输入歌手名',
      content_9: '选择文件',
      content_10: '覆盖文件',
      content_11: '未选择文件',
      content_12: '文件名存在非法字符字符(英文半角符号)',
      content_13: '必须选择音乐类型',
      content_14: '文件格式不合符，仅支持MP3格式'
    }
  },
  about: {
    intro: {
      title: '关于weelife',
      content_1: 'Weelife是一款专为Z世代年轻人打造、走在线上社交潮流前沿的虚拟现实语音社交产品，在次世代游戏制作工艺的加持下，将高品质的游戏化虚拟形象、虚拟场景与语音社交相结合，在移动端、VR端给社交用户带来前所未有的“超临场”社交体验。',
      content_2: '在Weelife世界中，可以通过精美的形象和服饰搭配来彰显自己的个性人设；丰富、有趣的社交互动玩法，让新朋友之间的社交破冰舒适而自然；多样的社交场景，或温馨浪漫或惊险刺激，与朋友在共同的经历中建立羁绊；真实的语音、生动的肢体动作使互动过程更具情感和温度；甚至可以建造自己的梦想场景或家园与朋友一起游玩。Weelife的目标是，让每一位用户在这个充满活力和温情的乌托邦中，都可以收获到自己声音的回响，遇到灵魂的羁绊。',
      content_3: 'Weelife是一款尚为年轻的产品，需要不断的成长才能更好的服务于广大用户。无论您是玩家还是创作者，无论您在世界何处，我们都期待聆听您的意见和建议，与我们一起共建理想中的乌托邦。'
    },
    culture: {
      title: '企业文化',
      content_1: '开放透明',
      content_2: '简单谦逊',
      content_3: '挑战极致',
      content_4: '实干创业'
    },
    welfare: {
      title: '福利待遇'
    },
    contact: {
      title: '联系我们',
      content_1: '地址：广州市天河区中山大道西238号勤天大厦402',
      content_2: '电话：020-66837100',
      content_3: 'HR邮箱：'
    }
  },
  recharge: {
    header: '账户充值',
    title: {
      title_1: '请输入您的账号ID',
      title_2: '选择储值方式',
      title_3: '选择储值金额'
    },
    box_1: {
      uid: '账号ID',
      placeholder: '请输入您的账号ID',
      btn: '验证',
      switchText: '切换账号'
    },
    bottom: {
      price: '应付金额',
      pay: '支付'
    },
    help: {
      header: '如何查看账号ID？',
      title_1: '打开Weelife app，找到【我的】页面',
      title_2: '在你的ID旁边点击【复制】按钮即可',
      confirm_btn: '知道了!'
    },
    info: {
      header: '订单详情信息',
      title: '请确认您的充值信息',
      nickname: '昵称',
      recharge: '充值金额',
      total_price: '总价格',
      cancel: '取消',
      confirm: '确认'
    },
    error: {
      uid_not: '此账号ID不存在'
    },
    order: {
      submit_loading: '订单提交中...',
      inquire_loading: '订单查询中...',
      success: '支付成功',
      success_info: '订单充值成功！感谢您的使用和支持，请返回weelife查看',
      timed_out: '订单查询超时',
      timed_out_info: '订单查询超时，点击确认后继续查询',
      timed_out_info_two: '订单查询超时，请返回weelife-系统通知-联系客服查询'
    }
  },
  language: {
    zh: '中文',
    'zh-Hant': '中文繁体',
    en: '英文',
    ar: '阿拉伯语',
    in: '印度尼西亚语',
    ms: '马来语',
    tl: '菲律宾语',
    vi: '越南语',
    th: '泰语'
  },
  download: {
    btn: '立即下载'
  },
  popup: {
    cancel: '取消',
    confirm: '确认'
  }
}
