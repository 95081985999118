import { multipartUpload, multipartUploadOption } from '@/api/request/aliOss'
import { ref, watch } from 'vue'
import { useOss } from './useOss'
import { OssMultipartUploadResult } from '@/api/types/aliOss'
import { filter } from 'lodash'

export interface ossUploadQueueListType {
  uid: number
  name: string
  status: string
}

const { getAilOssConfig, ossConfig, refreshSTSTokenInterval } = useOss()

/** 储存上传文件列表 */
const ossUploadQueueList = ref<ossUploadQueueListType[]>([])

export function useUpload() {
  const progressNum = ref<number>(0)
  const uploadStatus = ref<string>('')
  /** 存储上传中文件列表 */
  const uploadQueueList = ref<ossUploadQueueListType[]>([])

  /** 上传文件队列操作 */
  const uploadQueue = (val: ossUploadQueueListType) => {
    if (ossUploadQueueList.value.length === 0) {
      ossUploadQueueList.value.push(val)
    } else {
      if (val.status === 'start') {
        ossUploadQueueList.value.forEach((item) => {
          if (item.uid !== val.uid && item.name !== val.name) {
            ossUploadQueueList.value.push(val)
          }
        })
      } else {
        ossUploadQueueList.value.forEach((item) => {
          if (item.uid === val.uid && item.name === val.name) {
            item.status = val.status
          }
        })
      }
    }
  }

  /**
   * 使用阿里oss分片上传
   * @param fileType 文件类型
   * @param file 文件
   * @returns
   */
  async function aliMultipartUpload(fileType: number, file: File, option?: Partial<multipartUploadOption>) {
    const webkitRelativeFolder = file.webkitRelativePath ? file.webkitRelativePath.split('/').length > 0 ? file.webkitRelativePath.split('/')[0] : '' : ''
    uploadStatus.value = 'start'
    uploadQueue({ uid: file.uid, name: file.name, status: uploadStatus.value })
    if (!option?.ossConfig) {
      await getAilOssConfig(fileType)
    }
    if (!ossConfig.value && !option?.ossConfig) {
      uploadStatus.value = 'error'
      uploadQueue({ uid: file.uid, name: file.name, status: uploadStatus.value })
      return new Promise((resolve: (value: OssMultipartUploadResult) => void, reject) => {
        reject(Error('oss凭证不存在'))
      })
    }
    const config = Object.assign({}, option?.ossConfig || ossConfig.value, {
      cname: false,
      refreshSTSTokenInterval
    })
    uploadStatus.value = 'loading'
    return new Promise((resolve: (value: OssMultipartUploadResult) => void, reject) => {
      multipartUpload(config, file, (progress) => {
        progressNum.value = Number((progress * 100).toFixed(2))
      }, { destination: option?.destination }).then(res => {
        uploadStatus.value = 'success'
        uploadQueue({ uid: file.uid, name: file.name, status: uploadStatus.value })
        const result = Object.assign({ webkitRelativeFolder: webkitRelativeFolder }, res)
        progressNum.value = 0
        resolve(result)
      }).catch(err => {
        uploadStatus.value = 'error'
        progressNum.value = 0
        uploadQueue({ uid: file.uid, name: file.name, status: uploadStatus.value })
        reject(err)
      })
    })
  }

  watch(() => ossUploadQueueList.value, (val) => {
    if (!val.length) {
      uploadQueueList.value = []
      return
    }
    uploadQueueList.value = filter(val, ['status', 'start'])
    if (!uploadQueueList.value.length) {
      ossUploadQueueList.value = []
    }
  }, { deep: true })

  return { aliMultipartUpload, progressNum, uploadStatus, uploadQueueList }
}
