
import { reactive, toRefs, Ref, PropType, watchEffect, ref, defineAsyncComponent } from 'vue'
import { Popup, Button } from 'vant'
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'

interface MDialogState {
  isShowDialog: Ref<boolean>
  vanPopupStyle: object
  handleClickBtn: () => void
  handleClose: () => void
}
export default {
  name: 'MDialog',
  components: {
    [Popup.name]: Popup,
    [Button.name]: Button,
    MNavBar: defineAsyncComponent(() => import('./nav-bar.vue')),
    QrCode: defineAsyncComponent(() => import('../../components/qr-code/src/qrcode.vue'))
  },
  props: {
    show: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: false
    },
    style: {
      type: Object as PropType<{}>,
      default: () => {
        return {
          width: '40%',
          minWidth: '320px',
          maxWidth: '320px'
        }
      }
    },
    headerText: {
      type: String as PropType<string>,
      default: '手机扫码下载weelife App'
    },
    btnText: {
      type: String as PropType<string>,
      default: '下载App'
    },
    qrCodeUrl: {
      type: String as PropType<string>,
      default: window.location.href
    },
    qrCodeLogo: {
      type: String as PropType<string>,
      default: 'logo.png'
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: false
    },
    onDownload: {
      type: Function as PropType<() => void>,
      required: false
    }
  },
  setup(
    props: Readonly<{
      show: boolean
      style: object
      onClose?: () => void
      onDownload?: () => void
    }>
  ): MDialogState {
    const state = reactive({
      isShowDialog: props.show,
      vanPopupStyle: props.style
    })

    watchEffect(() => {
      state.vanPopupStyle = props.style
    })

    const contentWeight = ref(window.innerWidth)

    useWindowSizeFn(
      () => {
        contentWeight.value = window.innerWidth
        if (contentWeight.value <= 320) {
          state.vanPopupStyle = {
            minWidth: '280px'
          } as object
        }
      },
      200,
      { immediate: true }
    )

    watchEffect(() => {
      state.isShowDialog = props.show
    })

    const handleClickBtn = () => {
      props.onDownload && props.onDownload()
    }

    const handleClose = () => {
      props.onClose && props.onClose()
    }

    return {
      ...toRefs(state),
      handleClickBtn,
      handleClose
    }
  }
}
